<template>
  <section class="change-data" :class="platform" data-t="change-data-3nvy">
    <h2 v-if="platform === 'desktop'" class="title">
      {{ t('verification.changePersonalData') }}
    </h2>
    <StDrawerHeader
      v-else
      :title="t('verification.changePersonalData')"
      with-drag-line-padding
    />
    <div class="content">
      <Transition name="fade" mode="out-in">
        <UserVerificationForm
          v-if="status === 'success' && data"
          :initial-values="data"
          @success="handleSuccess"
        />
        <div v-else class="loader">
          <StSpinner size="32" />
        </div>
      </Transition>
    </div>
  </section>
</template>

<script setup lang="ts">
const { status, data } = useStFetch('/user-verification/unverified-data/get', {
  method: 'post',
  server: false,
  lazy: true,
})

const { platform } = usePlatform()

const { t } = useI18n()

const toast = useToast()

const router = useRouter()
function handleSuccess() {
  toast.open({
    label: t('userVerificationModal.dataChangedSuccessfully'),
    type: 'positive',
  })
  router.replace({ query: {} })
}
</script>

<style scoped>
.change-data {
  width: 480px;
}

.title {
  margin: 0;
  padding: var(--spacing-300) var(--spacing-300) 0;
  font: var(--desktop-text-xl-semibold);
}

.content {
  padding: var(--spacing-250) var(--spacing-300) var(--spacing-300);
}

.mobile .content {
  padding: var(--spacing-100) var(--spacing-200) var(--spacing-300);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 406px;
  height: 456px;
}

.mobile .loader {
  width: auto;
  height: 376px;
}

.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-from {
  transform: translateY(15px);
}

.mobile {
  &.change-data {
    width: 100%;
  }
}
</style>
